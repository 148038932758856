import React from 'react';
import './FAQ.scss';
import OurClientsT from '../OurClients/OurClients';
import Accordion from '../Accordion/Accordion';
import { withTranslation } from 'react-i18next';


class FAQ extends React.Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    const { t } = this.props;

    return(
      <div className="faq-page">
        <div className="faq-page__intro">
          <div className="flex-container">
            <div className="line"></div>
            <div className="title">{t('intro.title')}</div>
            <div className="main-text">{t('intro.main-text')}</div>
          </div>
        </div>
        <div className="faq-page__image">
        </div>
        <div className="faq-page__questions">
          <div className="flex-container">
            <div className="questions-block">
              <Accordion title={t('q-1.q')}>
                {t('q-1.a')}
              </Accordion>
              <Accordion title={t('q-2.q')}>
                {t('q-2.a')}
              </Accordion>
              <Accordion title={t('q-3.q')}>
                {t('q-3.a')}
              </Accordion>
              <Accordion title={t('q-4.q')}>
                {t('q-4.a')}
              </Accordion>
              <Accordion title={t('q-5.q')}>
                {t('q-5.a')}
              </Accordion>
              <Accordion title={t('q-6.q')}>
                {t('q-6.a')}
              </Accordion>
              <Accordion title={t('q-7.q')}>
                {t('q-7.a')}
              </Accordion>
              <Accordion title={t('q-8.q')}>
                {t('q-8.a')}
              </Accordion>
              <Accordion title={t('q-9.q')}>
                {t('q-9.a')}
              </Accordion>
              <Accordion title={t('q-10.q')}>
                {t('q-10.a')}
              </Accordion>
            </div>
          </div>
        </div>
        <div className="faq-page__bots">
          <div className="flex-container">
            <div
              className="bot-link telegram"
              onClick={() => window.open("https://t.me/Urbanist_support_bot", "_blank")}

            >
              {t('buttons.b-1')}
            </div>
            <div
              className="bot-link viber"
              
            >
              {t('buttons.b-2')}
            </div>
          </div>
        </div>
        <div className="faq-page__contacts">
          <div className="flex-container">
            <div className="contacts-info">
              <div className="line"></div>
              <div className="title">{t('contacts.title')}</div>
              <div className="contact">
                <div className="contact__title">
                {t('contacts.sub-title-1')}
                </div>
                <div className="contact__info">
                  {t('contacts.main-text-1')}<br/>{t('contacts.main-text-1-2')}
                </div>
              </div>
              <div className="contact">
                <div className="contact__title">
                {t('contacts.sub-title-2')}
                </div>
                <div className="contact__info">
                {t('contacts.main-text-2')}
                </div>
              </div>
              <div className="contact">
                <div className="contact__title">
                {t('contacts.sub-title-3')}
                </div>
                <div className="contact__info">
                {t('contacts.main-text-3')}
                </div>
              </div>
            </div>
            <div className="downloads">
              <div className="title">{t('downloads.title')}</div>
              <div className="main-text">{t('downloads.main-text')}</div>
              <div className="download-buttons">
                <div
                  className="button apple-button"
                  onClick={() => {window.open("https://apps.apple.com/en/app/urbanist/id1575719164", "_blank"); this.props.eventTracker('Завантаження','Відкрили посилання на AppStore')}}
                  ></div>
                <div
                  className="button android-button"
                  onClick={() => {window.open("https://play.google.com/store/apps/details?id=com.dodotap.urbanist", "_blank") ; this.props.eventTracker('Завантаження','Відкрили посилання на Play Store')}}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <OurClientsT/>
      </div>
    )
  }
}

const FAQT = withTranslation('faq')(FAQ);
export default FAQT;

