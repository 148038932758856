import React from 'react';
import './Slider.scss';

let intervalId;

class Slider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			prevDisable: true,
			nextDisable: this.refs && this.refs.offsetWidth >= this.refs.scrollWidth ? true : false
		};
	}

 componentDidMount() {
	 intervalId = setInterval(() => {
			this.checkButtons(this.refs.offsetWidth, this.refs.scrollWidth);
	 }, 2500)
 }
	checkButtons = (offsetWidthValue, scrollWidthValue) => {
		setTimeout(() => {
			this.setState({
				prevDisable: this.refs.scrollLeft <= 0 ? true : false,
				nextDisable: this.refs.scrollLeft + offsetWidthValue >= scrollWidthValue ? true : false
			});
		}, 450);
	};

	componentWillUnmount() {
		clearInterval(intervalId);
	}

	render() {
		const offsetWidthValue = this.refs.offsetWidth,
			scrollWidthValue = this.refs.scrollWidth;
		return (
			<div
				className='slider-container--news'
				ref={el => {
					this.refs = el;
				}}
			>
				<div className='slider-wrapper--news'>{this.props.children}</div>
				<div
					className={`btn-news prev-news ${this.state.prevDisable ? 'disable' : ''}`}
					disabled={this.state.prevDisable}
					onClick={() => {
						this.refs.scrollLeft -= offsetWidthValue / 2;
						this.checkButtons(offsetWidthValue, scrollWidthValue);
					}}
				>
          <div className="arrow"></div>
				</div>
				<div
					className={`btn-news next-news ${this.state.nextDisable ? 'disable' : ''}`}
					disabled={this.state.nextDisable}
					onClick={() => {
						this.refs.scrollLeft += offsetWidthValue / 2;
						this.checkButtons(offsetWidthValue, scrollWidthValue);
					}}
				>
        <div className="arrow"></div>
				</div>
			</div>
		);
	}
}

export default Slider;


