import React from 'react';
import './Partners.scss';
import InputMask from 'react-input-mask';
import Accordion from '../Accordion/Accordion';
import OurClientsT from '../OurClients/OurClients';
import getCallback from '../../API/Callback';
import SuccessPopup from './SuccessPopup/SuccessPopup';
import {withTranslation} from 'react-i18next';
import CallFormT from './CallForm/Form';

class Partners extends React.Component {
    state = {
        name: '',
        phone: '',
        email: '',
        showPopup: false,
        showForm: false,
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    showPopup() {
        this.setState({showPopup: true});
    }

    hidePopup() {
        this.setState({showPopup: false});
    }

    showForm() {
        this.setState({showForm: true});
    }

    hideForm() {
        this.setState({showForm: false});
    }

    onSubmit() {
        const {name, phone, email} = this.state;

        switch (true) {
            case !name :
                alert('Введіть ім\'я.');
                return;
            case (phone.replace(/\D/g, '').length < 12):
            case !phone :
                alert('Введіть телефон');
                return;
            case !email :
                alert('Введіть імейл.');
                return;
            default:
                break;
        }

        // const formData = new FormData();
        // formData.append('name', name);
        // formData.append('email', email);
        // formData.append('phone_number', phone);

        const data = {
            'name': name,
            'email': email,
            'phone_number': phone
        }

        const response = getCallback(data);
        response.then(
            data => {
                // if (!data.ok) {
                //     throw new Error('An error has occurred while uploading info.');
                // }
                if (data.status !== 200) {
                    throw new Error('An error has occurred while uploading info.');
                }

                this.setState({
                    showPopup: true,
                    name: '',
                    phone: '',
                    email: '',
                });

                this.props.eventTracker('Партнерам', 'Заповнив форму Передзвонити мені');
            }
        ).catch(alert);
    }


    render() {
        const {t} = this.props;

        return (
            <div className="partners-page">
                {
                    this.state.showPopup &&
                    <SuccessPopup handleClose={this.hidePopup.bind(this)}/>
                }
                <div className="partners-page__intro">
                    {
                        this.state.showForm &&
                        <CallFormT handleClose={this.hideForm.bind(this)} showPopup={this.showPopup.bind(this)}
                                   eventTracker={this.props.eventTracker.bind(this)}/>
                    }
                    <div className="flex-container">
                        <div className="line"></div>
                        <div className="title">{t(`intro.${window.location.href.split('/').pop()}`)}</div>
                        <div className="main-text">{t('intro.main-text')}</div>
                        <div className="downloads">
                            {/*<div*/}
                            {/*    className="apple-button button"*/}
                            {/*    onClick={() => window.open('https://apps.apple.com/en/app/urbanist/id1575719164', "_blank")}*/}
                            {/*></div>*/}
                            {/*<div*/}
                            {/*    onClick={() => window.open("https://play.google.com/store/apps/details?id=com.dodotap.urbanist", "_blank")}*/}
                            {/*    className="android-button button"*/}
                            {/*></div>*/}
                            <button
                                className="button contact-button"
                                onClick={() => this.showForm()}
                            >
                                {t('contact-button')}
                            </button>
                        </div>
                        <div className="phone"></div>
                    </div>
                </div>
                <div className="partners-page__opportunities">
                    <div className="flex-container">
                        <div className="opportunities-list">
                            <div className="line"></div>
                            <div className="title">{t('opportunities.title')}</div>
                            <div className="mobile-image"></div>
                            <div className="list">
                                <div className="list-item">
                                    {t('opportunities.item-1')}
                                </div>
                                <div className="list-item">
                                    {t('opportunities.item-2')}
                                </div>
                                <div className="list-item">
                                    {t('opportunities.item-3')}
                                </div>
                                <div className="list-item">
                                    {t('opportunities.item-4')}
                                </div>
                                <div className="list-item">
                                    {t('opportunities.item-5')}
                                </div>
                                <div className="list-item">
                                    {t('opportunities.item-6')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="partners-page__advantages">
                    <div className="flex-container">
                        <div className="advantages-text">
                            <div className="line"></div>
                            <div className="title">{t('advantages.title')}</div>
                            <div className="main-text">{t('advantages.main-text')}</div>
                        </div>
                        <div className="advantages-cards">
                            <div className="card profit">
                                <div className="icon"></div>
                                <div className="title">{t('advantages.card-1.title')}</div>
                                <div className="main-text">{t('advantages.card-1.main-text')}</div>
                            </div>
                            <div className="card coverage">
                                <div className="icon"></div>
                                <div className="title">{t('advantages.card-2.title')}</div>
                                <div className="main-text">{t('advantages.card-2.main-text')}</div>
                            </div>
                            <div className="card bonuses">
                                <div className="icon"></div>
                                <div className="title">{t('advantages.card-3.title')}</div>
                                <div className="main-text">{t('advantages.card-3.main-text')}</div>
                            </div>
                            <div className="card support">
                                <div className="icon"></div>
                                <div className="title">{t('advantages.card-4.title')}</div>
                                <div className="main-text">{t('advantages.card-4.main-text')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="partners-page__contacts">
                    <div className="flex-container">
                        <div className="contacts-info">
                            <div className="line"></div>
                            <div className="title">{t('contacts.title')}</div>
                            <div className="contact">
                                <div className="contact__title">
                                    {t('contacts.sub-title-1')}
                                </div>
                                <div className="contact__info">
                                    {t('contacts.main-text-1')}<br/>{t('contacts.main-text-1-2')}
                                </div>
                            </div>
                            <div className="contact">
                                <div className="contact__title">
                                    {t('contacts.sub-title-2')}
                                </div>
                                <div className="contact__info">
                                    {t('contacts.main-text-2')}
                                </div>
                            </div>
                            <div className="contact">
                                <div className="contact__title">
                                    {t('contacts.sub-title-3')}
                                </div>
                                <div className="contact__info">
                                    {t('contacts.main-text-3')}
                                </div>
                            </div>
                        </div>
                        <div className="contact-me">
                            <div className="title">{t('contact-me.title')}</div>
                            <div className="main-text">{t('contact-me.main-text')}</div>
                            <input
                                type="text"
                                className="text-input"
                                placeholder={t('contact-me.input')}
                                value={this.state.name}
                                onChange={(e) => this.setState({name: e.target.value})}
                            />
                            <InputMask
                                className='text-input'
                                value={this.state.phone}
                                onChange={e => this.setState({phone: e.target.value})}
                                mask="+ 38 \(099) 999 99 99"
                                maskChar=" "
                                placeholder='+ 38 (0**) *** ** **'
                            />
                            <input
                                type="text"
                                className="text-input"
                                placeholder='Email'
                                value={this.state.email}
                                onChange={(e) => this.setState({email: e.target.value})}
                            />
                            <div
                                className="send-button"
                                onClick={() => this.onSubmit()}
                            >{t('contact-me.button')}</div>
                        </div>
                    </div>
                </div>
                <div className="partners-page__rates">
                    <div className="flex-container">
                        <div className="line"></div>
                        <div className="title">{t('rates.title')}</div>
                        <div className="rates-container">
                            <div className="rates-item">
                                <div className="rates-item__header">
                                    <div className="title">{t('rates.basic.title')}</div>
                                    <div className="cost">{t('rates.basic.price')}</div>
                                </div>
                                <div className="rates-item__list">
                                    <div className="list__item">
                                        {t('rates.basic.item-1')}
                                    </div>
                                    <div className="list__item">
                                        {t('rates.basic.item-2')}
                                    </div>
                                    <div className="list__item">
                                        {t('rates.basic.item-3')}
                                    </div>
                                    <div className="list__item">
                                        {t('rates.basic.item-4')}
                                    </div>
                                    <div className="list__item">
                                        {t('rates.basic.item-5')}
                                    </div>
                                </div>
                                <div
                                    className="rates-item__button"
                                    onClick={() => this.showForm()}
                                >
                                    {t('rates.button')}
                                </div>
                            </div>
                            <div className="rates-item rates-item__middle">
                                <div className="rates-item__header">
                                    <div className="title">{t('rates.premium.title')}</div>
                                    <div className="cost">{t('rates.premium.price')}</div>
                                </div>
                                <div className="rates-item__list">
                                    <div className="list__item">
                                        {t('rates.premium.item-1')}
                                    </div>
                                    <div className="list__item">
                                        {t('rates.premium.item-2')}
                                    </div>
                                    <div className="list__item">
                                        {t('rates.premium.item-3')}
                                    </div>
                                    <div className="list__item">
                                        {t('rates.premium.item-4')}
                                    </div>
                                    <div className="list__item">
                                        {t('rates.premium.item-5')}
                                    </div>
                                    <div className="list__item">
                                        {t('rates.premium.item-6')}
                                    </div>
                                    <div className="list__item">
                                        {t('rates.premium.item-7')}
                                    </div>
                                    <div className="list__item">
                                        {t('rates.premium.item-8')}
                                    </div>
                                </div>
                                <div
                                    className="rates-item__button"
                                    onClick={() => this.showForm()}
                                >
                                    {t('rates.button')}
                                </div>
                            </div>
                            {/* <div className="rates-item">
                <div className="rates-item__header">
                  <div className="title">{t('rates.platinum.title')}</div>
                  <div className="cost">{t('rates.platinum.price')}</div>
                </div>
                <div className="rates-item__list">
                  <div className="list__item">
                    {t('rates.platinum.item-1')}
                  </div>
                  <div className="list__item">
                  {t('rates.platinum.item-2')}
                  </div>
                  <div className="list__item">
                  {t('rates.platinum.item-3')}
                  </div>
                  <div className="list__item">
                  {t('rates.platinum.item-4')}
                  </div>
                  <div className="list__item">
                  {t('rates.platinum.item-5')}
                  </div>
                  <div className="list__item">
                  {t('rates.platinum.item-6')}
                  </div>
                  <div className="list__item">
                  {t('rates.platinum.item-7')}
                  </div>
                  <div className="list__item">
                  {t('rates.platinum.item-8')}
                  </div>
                  <div className="list__item">
                  {t('rates.platinum.item-9')}
                  </div>
                  <div className="list__item">
                  {t('rates.platinum.item-10')}
                  </div>
                  <div className="list__item">
                  {t('rates.platinum.item-11')}
                  </div>
                </div>
              </div> */}
                        </div>
                    </div>
                </div>
                <div className="partners-page__faq">
                    <div className="flex-container">
                        <div className="faq-container">
                            <div className="line"></div>
                            <div className="title">{t('questions.title')}</div>
                            <Accordion title={t('questions.q-1.q')}>
                                {t('questions.q-1.a')}
                            </Accordion>
                            <Accordion title={t('questions.q-2.q')}>
                                {t('questions.q-2.a')}
                            </Accordion>
                            <Accordion title={t('questions.q-3.q')}>
                                {t('questions.q-3.a')}
                            </Accordion>
                        </div>
                    </div>
                </div>
                <div className="partners-page__contacts">
                    <div className="flex-container">
                        <div className="contacts-info">
                            <div className="line"></div>
                            <div className="title">{t('contacts.title')}</div>
                            <div className="contact">
                                <div className="contact__title">
                                    {t('contacts.sub-title-1')}
                                </div>
                                <div className="contact__info">
                                    {t('contacts.main-text-1')}<br/>{t('contacts.main-text-1-2')}
                                </div>
                            </div>
                            <div className="contact">
                                <div className="contact__title">
                                    {t('contacts.sub-title-2')}
                                </div>
                                <div className="contact__info">
                                    {t('contacts.main-text-2')}
                                </div>
                            </div>
                            <div className="contact">
                                <div className="contact__title">
                                    {t('contacts.sub-title-3')}
                                </div>
                                <div className="contact__info">
                                    {t('contacts.main-text-3')}
                                </div>
                            </div>
                        </div>
                        <div className="contact-me">
                            <div className="title">{t('contact-me.title')}</div>
                            <div className="main-text">{t('contact-me.main-text')}</div>
                            <input
                                type="text"
                                className="text-input"
                                placeholder={t('contact-me.input')}
                                value={this.state.name}
                                onChange={(e) => this.setState({name: e.target.value})}
                            />
                            <InputMask
                                className='text-input'
                                value={this.state.phone}
                                onChange={e => this.setState({phone: e.target.value})}
                                mask="+ 38 \(099) 999 99 99"
                                maskChar=" "
                                placeholder='+ 38 (0**) *** ** **'
                            />
                            <input
                                type="text"
                                className="text-input"
                                placeholder='Email'
                                value={this.state.email}
                                onChange={(e) => this.setState({email: e.target.value})}
                            />
                            <div
                                className="send-button"
                                onClick={() => this.onSubmit()}
                            >{t('contact-me.button')}</div>
                        </div>
                    </div>
                </div>
                <OurClientsT/>
            </div>
        )
    }
}

const PartnersT = withTranslation('partners')(Partners);
export default PartnersT;