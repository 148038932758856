import React from 'react';
import './AboutUs.scss';
import OurClientsT from '../OurClients/OurClients';
import Downloads from '../Downloads/Downloads';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

class AboutUs extends React.Component {

  state = {
    selectedBlock: 1,
  }

  componentDidMount() {
    window.scrollTo(0,0);
    ReactGA.pageview('/' + window.location.hash.split('/').pop());
  }

  nextBlock() {
    if (this.state.selectedBlock < 4) {
      this.setState(state => ({selectedBlock: state.selectedBlock + 1}));
    }
  }

  previousBlock() {
    if (this.state.selectedBlock > 1) {
      this.setState(state => ({selectedBlock: state.selectedBlock - 1}));
    }
  }

  render() {
    const { t } = this.props;

    return(
      <div className="about-us">
        <div className="about-us__info">
          <div className="flex-container">
            <div className="info-block">
              <div className="info-block__text">
                <div className="line"></div>
                <div className="title">{t('intro.title')}</div>
                <div className="main-text">
                  {t('intro.main-text')}
                </div>
              </div>
            <div className="animated-block">
              <div className="u-image"></div>
              <div className="u-shadow"></div>
            </div>
            </div>
          </div>
        </div>
        {/*<div className="about-us__story">*/}
        {/*  <div*/}
        {/*    className={classNames("block block-1",{selected: this.state.selectedBlock === 1})}*/}
        {/*    // onClick={() => this.setState({selectedBlock: 1})}*/}
        {/*  >*/}
        {/*    <div className={classNames("content", {'content-selected': this.state.selectedBlock === 1})}>*/}
        {/*      <div className="line"></div>*/}
        {/*      <div className="title">{t("story.card-1.title")}</div>*/}
        {/*      <div className="icon"></div>*/}
        {/*      <div className="sub-title">{t("story.card-1.sub-title")}</div>*/}
        {/*      <div className="main-text">{t("story.card-1.main-text")}*/}
        {/*      </div>*/}
        {/*      <div*/}
        {/*        className="button next"*/}
        {/*        onClick={() => this.nextBlock()}*/}
        {/*      ></div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    className={classNames("block block-2",{selected: this.state.selectedBlock === 2})}*/}
        {/*    // onClick={() => this.setState({selectedBlock: 2})}*/}
        {/*  >*/}
        {/*    <div className={classNames("content", {'content-selected': this.state.selectedBlock === 2})}>*/}
        {/*      <div className="line"></div>*/}
        {/*      <div className="title">{t("story.card-1.title")}</div>*/}
        {/*      <div className="icon"></div>*/}
        {/*      <div className="sub-title">{t("story.card-2.sub-title")}</div>*/}
        {/*      <div className="main-text">{t("story.card-2.main-text")}*/}
        {/*      </div>*/}
        {/*      <div */}
        {/*        className="button next"*/}
        {/*        onClick={() => this.nextBlock()}*/}
        {/*        ></div>*/}
        {/*      <div*/}
        {/*        className="button prev"*/}
        {/*        onClick={() => this.previousBlock()}*/}
        {/*      ></div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    className={classNames("block block-3",{selected: this.state.selectedBlock === 3})}*/}
        {/*    // onClick={() => this.setState({selectedBlock: 3})}*/}
        {/*  >*/}
        {/*    <div className={classNames("content", {'content-selected': this.state.selectedBlock === 3})}>*/}
        {/*      <div className="line"></div>*/}
        {/*      <div className="title">{t("story.card-1.title")}</div>*/}
        {/*      <div className="icon"></div>*/}
        {/*      <div className="sub-title">{t("story.card-3.sub-title")}</div>*/}
        {/*      <div className="main-text">{t("story.card-3.main-text")}*/}
        {/*      </div>*/}
        {/*      <div*/}
        {/*        className="button next"*/}
        {/*        onClick={() => this.nextBlock()}*/}
        {/*      ></div>*/}
        {/*      <div*/}
        {/*        className="button prev"*/}
        {/*        onClick={() => this.previousBlock()}*/}
        {/*      ></div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div*/}
        {/*    className={classNames("block block-4",{selected: this.state.selectedBlock === 4})}*/}
        {/*    // onClick={() => this.setState({selectedBlock: 4})}*/}
        {/*  >*/}
        {/*    <div className={classNames("content", {'content-selected': this.state.selectedBlock === 4})}>*/}
        {/*      <div className="line"></div>*/}
        {/*      <div className="title">{t("story.card-1.title")}</div>*/}
        {/*      <div className="icon"></div>*/}
        {/*      <div className="sub-title">{t("story.card-4.sub-title")}</div>*/}
        {/*      <div className="main-text">{t("story.card-4.main-text")}*/}
        {/*      </div>*/}
        {/*      <div*/}
        {/*        className="button prev"*/}
        {/*        onClick={() => this.previousBlock()}*/}
        {/*      ></div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="about-us__advantages">
          <div className="flex-container">
          <div className="line"></div>
            <div className="title">{t("advantages.title")}</div>
            <div className="grid-container">
              <div className="advantage-card card-1">
                <div className="line"></div>
                <div className="title">{t("advantages.card-1.title")}</div>
                <div className="main-text">{t("advantages.card-1.main-text")}</div>
              </div>
              <div className="advantage-card card-2">
                <div className="line"></div>
                <div className="title">{t("advantages.card-2.title")}</div>
                <div className="main-text">{t("advantages.card-2.main-text")}</div>
              </div>
              <div className="advantage-card card-3">
                <div className="line"></div>
                <div className="title">{t("advantages.card-3.title")}</div>
                <div className="main-text">{t("advantages.card-3.main-text")}</div>
              </div>
              <div className="advantage-card card-4">
                <div className="line"></div>
                <div className="title">{t("advantages.card-4.title")}</div>
                <div className="main-text">{t("advantages.card-4.main-text")}</div>
              </div>
              <div className="advantages-phone"></div>
            </div>
          </div>
        </div>
        <div className="about-us__downloads">
          <div className="flex-container">
            <Downloads eventTracker={this.props.eventTracker.bind(this)}/>
          </div>
        </div>
        <OurClientsT />
      </div>
    );
  }
}

const AboutUsT = withTranslation('about')(AboutUs);

export default AboutUsT;