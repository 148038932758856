import React from 'react';
import './DownloadsPopup.scss';
import { withTranslation } from 'react-i18next';


class DownloadsPopup extends React.Component {
  render() {
    const { t } = this.props;

    return(
      <>
      <div
        className="blur-background"
        onClick={() => this.props.handleClose()}
      ></div>
      <div className="downloads-popup">
        <div className="purple-top"></div>
        <div className="circle-image"></div>
        <div className="title">{t('popup-1')}<br/>{t('popup-2')}</div>
        <div className="buttons">
          <div
            className="button apple-button"
            onClick={() => {window.open("https://apps.apple.com/us/app/urbanist/id1575719164?uo=4&at=11l6hc&app=itunes&ct=fnd", "_blank");this.props.eventTracker('Завантаження','Відкрили посилання на AppStore'); this.props.handleClose()}}
          ></div>
          <div
            className="button android-button"
            onClick={() => {window.open("https://play.google.com/store/apps/details?id=com.dodotap.urbanist", "_blank");this.props.eventTracker('Завантаження','Відкрили посилання на Play Store'); this.props.handleClose()}}
          ></div>
        </div>
      </div>
      </>
    )
  }
}

const DownloadsPopupT = withTranslation('header')(DownloadsPopup);
export default DownloadsPopupT;