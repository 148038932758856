import React from "react";
import './Form.scss';
import InputMask from 'react-input-mask';
import {withTranslation} from 'react-i18next';
import getCallback from '../../../API/Callback';


class CallForm extends React.Component {
    state = {
        name: '',
        phone: '',
        email: '',
        formIsSent: false,
    }

    onSubmit() {
        const {name, phone, email} = this.state;

        switch (true) {
            case !name :
                alert('Введіть ім\'я.');
                return;
            case (phone.replace(/\D/g, '').length < 12):
            case !phone :
                alert('Введіть телефон');
                return;
            case !email :
                alert('Введіть імейл.');
                return;
            default:
                break;
        }

        // const formData = new FormData();
        // formData.append('name', name);
        // formData.append('email', email);
        // formData.append('phone_number', phone);

        const data = {
            'name': name,
            'email': email,
            'phone_number': phone
        }


        // const response = getCallback(formData);
        const response = getCallback(data);
        response.then(
            data => {
                // if (!data.ok) {
                //     throw new Error('An error has occurred while uploading info.');
                // }
                if (data.status !== 200) {
                    throw new Error('An error has occurred while uploading info.');
                }

                this.setState({
                    name: '',
                    phone: '',
                    email: '',
                });

                this.props.eventTracker('Партнерам', 'Заповнив форму Передзвонити мені');
                this.props.handleClose();
                this.props.showPopup();
            }
        ).catch(alert);
    }

    render() {
        const {t} = this.props;

        return (
            <>
                <div
                    className="blur-background"
                    onClick={() => this.props.handleClose()}
                ></div>
                <div className="call-form">
                    <ul className="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <div className="title">{t('contact-me.title')}</div>
                    <div className="main-text">{t('contact-me.main-text')}</div>
                    <input
                        type="text"
                        className="text-input"
                        placeholder={t('contact-me.input')}
                        value={this.state.name}
                        onChange={(e) => this.setState({name: e.target.value})}
                    />
                    <InputMask
                        className='text-input'
                        value={this.state.phone}
                        onChange={e => this.setState({phone: e.target.value})}
                        mask="+ 38 \(099) 999 99 99"
                        maskChar=" "
                        placeholder='+ 38 (0**) *** ** **'
                    />
                    <input
                        type="text"
                        className="text-input"
                        placeholder='Email'
                        value={this.state.email}
                        onChange={(e) => this.setState({email: e.target.value})}
                    />
                    <div
                        className="send-button"
                        onClick={() => this.onSubmit()}
                    >{t('contact-me.button')}</div>
                </div>
            </>
        )
    }
}

const CallFormT = withTranslation('partners')(CallForm);
export default CallFormT;