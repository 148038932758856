import React from 'react';
import './ServicesSlider.scss';

class ServicesSlider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			prevDisable: true,
			nextDisable: this.refs && this.refs.offsetWidth >= this.refs.scrollWidth ? true : false,
			width: 0,

		};
	}

 componentDidMount() {
  this.checkButtons(this.refs.offsetWidth, this.refs.scrollWidth);
 }
	checkButtons = (offsetWidthValue, scrollWidthValue) => {
		setTimeout(() => {
			this.setState({
				prevDisable: this.refs.scrollLeft <= 0 ? true : false,
				nextDisable: this.refs.scrollLeft + offsetWidthValue >= scrollWidthValue ? true : false
			});
		}, 450);
	};

	render() {
		const offsetWidthValue = window.innerWidth > 505 ? 490 : 290,
		scrollWidthValue = this.refs.scrollWidth;
			
		return (
			<div
				className='slider-container'
				ref={el => {
					this.refs = el;
				}}
			>
				<div className='slider-wrapper'>{this.props.children}</div>
				<div
					className={`btn prev ${this.state.prevDisable ? 'disable' : ''}`}
					disabled={this.state.prevDisable}
					onClick={() => {
						this.refs.scrollLeft -= offsetWidthValue;
						this.checkButtons(offsetWidthValue, scrollWidthValue);
						this.props.prevPic();
					}}
				>
          <div className="arrow"></div>
				</div>
				<div
					className={`btn next ${this.state.nextDisable ? 'disable' : ''}`}
					disabled={this.state.nextDisable}
					onClick={() => {
						this.refs.scrollLeft += offsetWidthValue;
						this.checkButtons(offsetWidthValue, scrollWidthValue);
						this.props.nextPic();
					}}
				>
        <div className="arrow"></div>
				</div>
			</div>
		);
	}
}

export default ServicesSlider;


