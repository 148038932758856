import React from 'react';
import './SuccessPopup.scss';

class SuccessPopup extends React.Component {
  render() {
    return(
      <>
      <div
        className="blur-background"
        onClick={() => this.props.handleClose()}
      ></div>
      <div className="success-popup">
        <div className="purple-top"></div>
        <div className="circle-image"></div>
        <div className="title">Чудово!</div>
        <div className="main-text">Ваш запит на зворотній дзвінок надіслано. Ми передзвонимо вам протягом півгодини!</div>
        <div
          className="exit-button"
          onClick={() => this.props.handleClose()}
        >
          Добре
        </div>
      </div>
      </>
    )
  }
}

export default SuccessPopup;