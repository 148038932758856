import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.jsx';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import main_ua from './Translation/UA/MainPage.json';
import main_ru from './Translation/RU/MainPage.json';
import main_en from './Translation/EN/MainPage.json';
import about_ua from './Translation/UA/AboutUs.json';
import about_ru from './Translation/RU/AboutUs.json';
import about_en from './Translation/EN/AboutUs.json';
import news_ua from './Translation/UA/News.json';
import news_ru from './Translation/RU/News.json';
import news_en from './Translation/EN/News.json';
import services_ua from "./Translation/UA/Services.json";
import services_ru from "./Translation/RU/Services.json";
import services_en from "./Translation/EN/Services.json";
import partners_ua from './Translation/UA/Partners.json';
import partners_ru from './Translation/RU/Partners.json';
import partners_en from './Translation/EN/Partners.json';
import faq_ua from './Translation/UA/FAQ.json';
import faq_ru from './Translation/RU/FAQ.json';
import faq_en from './Translation/EN/FAQ.json';
import header_ua from './Translation/UA/Header.json';
import header_ru from './Translation/RU/Header.json';
import header_en from './Translation/EN/Header.json';
import ourclients_ua from './Translation/UA/OurClients.json';
import ourclients_ru from './Translation/RU/OurClients.json';
import ourclients_en from './Translation/EN/OurClients.json';
import downloads_ua from './Translation/UA/Downloads.json';
import downloads_ru from './Translation/RU/Downloads.json';
import downloads_en from './Translation/EN/Downloads.json';
import footer_ua from './Translation/UA/Footer.json';
import footer_ru from './Translation/RU/Footer.json';
import footer_en from './Translation/EN/Footer.json';
import contacts_ua from './Translation/UA/Contacts.json';
import contacts_ru from './Translation/RU/Contacts.json';
import contacts_en from './Translation/EN/Contacts.json';
import vacancies_ua from './Translation/UA/Vacancies.json';
import vacancies_ru from './Translation/RU/Vacancies.json';
import vacancies_en from './Translation/EN/Vacancies.json';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'ua',
  resources: {
    ua: {
      main: main_ua,
      about: about_ua,
      news: news_ua,
      services: services_ua,
      partners: partners_ua,
      faq: faq_ua,
      header: header_ua,
      ourclients: ourclients_ua,
      downloads: downloads_ua,
      footer: footer_ua,
      contacts: contacts_ua,
      vacancies: vacancies_ua,
    },
    ru: {
      main: main_ru,
      about: about_ru,
      header: header_ru,
      news: news_ru,
      faq: faq_ru,
      services: services_ru,
      ourclients: ourclients_ru,
      partners: partners_ru,
      downloads: downloads_ru,
      footer: footer_ru,
      contacts: contacts_ru,
      vacancies: vacancies_ru,
    },
    en: {
      main: main_en,
      about: about_en,
      header: header_en,
      news: news_en,
      faq: faq_en,
      partners: partners_en,
      services: services_en,
      ourclients: ourclients_en,
      downloads: downloads_en,
      footer: footer_en,
      contacts: contacts_en,
      vacancies: vacancies_en,
    }
  }
});


ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
        <App/>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
