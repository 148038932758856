const BASE_URL = 'https://urbanist.discount/career/form/';

async function sendCV(body) {
  const send = () => {
    return fetch(BASE_URL, {
      method: 'POST',
      mode: 'cors',
      cache: 'default',
      headers: {
        "Connection": 'keep-alive',
      },
      body: body,
    })
  }

  return await send();
}

export default sendCV;