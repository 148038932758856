import React from 'react';
import './NoMatch.scss';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import HideFooter from "../../Translation/ShowFooter";


class NoMatch extends React.Component {
  componentDidMount() {
    HideFooter.value = true;
    this.props.update();
  }

  componentWillUnmount() {
    HideFooter.value = false;
    this.props.update();
  }


  render() {
    const { t } = this.props;

    return(
      <div className="no-match--page">
        <div className="message">
          <div className="title">{t('404.title')}</div>
          <div className="main-text">{t('404.main-text-1')}</div>
          <div className="main-text">{t('404.main-text-2')}</div>
          <Link to={'/main'}>
            <div className="button">{t('404.button')}</div>
          </Link>
        </div>
      </div>
    )
  }
}

const NoMatchT = withTranslation('main')(NoMatch);
export default NoMatchT;