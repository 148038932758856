import React from 'react';
import './Vacancies.scss';
import VacanciesFormT from './VacanciesForm/VacanciesForm';
import SendPopupT from './Popup/Popup';
import {withTranslation} from 'react-i18next';

class Vacancies extends React.Component {
    state = {
        selectedCity: 'kiev',
        showForm: false,
        showPopup: false,
        selectedVacancy: {},
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    showForm(vacancy) {
        this.setState({
            showForm: true,
            selectedVacancy: vacancy,
        });
    }

    hideForm() {
        this.setState({showForm: false});
    }

    showPopup() {
        this.setState({showPopup: true});
    }

    hidePopup() {
        this.setState({showPopup: false});
    }


    render() {
        const {t} = this.props;
        const vacancies = [
            {
                title: t('v-1.title'),
                description1: t('v-1.description-1'),
                description2: [
                    {
                        class: 'sub-title',
                        text: t('v-1.description-2.sub-title-1'),
                    },
                    {
                        class: 'list',
                        text: t('v-1.description-2.list-1'),
                    },
                    {
                        class: 'sub-title',
                        text: t('v-1.description-2.sub-title-2'),
                    },
                    {
                        class: 'list',
                        text: t('v-1.description-2.list-2'),
                    },
                    {
                        class: 'sub-title',
                        text: t('v-1.description-2.sub-title-3'),
                    },
                    {
                        class: 'list',
                        text: t('v-1.description-2.list-3'),
                    },
                ],
                price: '500$',
            },
            {
                title: t('v-2.title'),
                description1: t('v-2.description-1'),
                description2: [
                    {
                        class: 'main-text',
                        text: t('v-2.description-2.main-text-1'),
                    },
                    {
                        class: 'main-text',
                        text: t('v-2.description-2.main-text-2'),
                    },
                    {
                        class: 'main-text',
                        text: t('v-2.description-2.main-text-3'),
                    },
                    {
                        class: 'main-text',
                        text: t('v-2.description-2.main-text-4'),
                    },
                    {
                        class: 'main-text',
                        text: t('v-2.description-2.main-text-5'),
                    },
                    {
                        class: 'main-text',
                        text: t('v-2.description-2.main-text-6'),
                    },
                    {
                        class: 'sub-title',
                        text: t('v-2.description-2.sub-title-1'),
                    },
                    {
                        class: 'list',
                        text: t('v-2.description-2.list-1'),
                    },
                    {
                        class: 'sub-title',
                        text: t('v-2.description-2.sub-title-2'),
                    },
                    {
                        class: 'list',
                        text: t('v-2.description-2.list-2'),
                    },
                    {
                        class: 'sub-title',
                        text: t('v-2.description-2.sub-title-3'),
                    },
                    {
                        class: 'list',
                        text: t('v-2.description-2.list-3'),
                    },
                    {
                        class: 'sub-title',
                        text: t('v-2.description-2.sub-title-4'),
                    },
                    {
                        class: 'list',
                        text: t('v-2.description-2.list-4'),
                    },
                    {
                        class: 'sub-title',
                        text: t('v-2.description-2.sub-title-5'),
                    },
                    {
                        class: 'main-text',
                        text: t('v-2.description-2.main-text-7'),
                    },
                ],
                price: '1000$',
            },
            {
                title: t('v-3.title'),
                description1: t('v-3.description-1'),
                description2: [
                    {
                        class: 'main-text',
                        text: t('v-3.description-2.main-text-1'),
                    },
                    {
                        class: 'main-text',
                        text: t('v-3.description-2.main-text-2'),
                    },
                    {
                        class: 'main-text',
                        text: t('v-3.description-2.main-text-3'),
                    },
                    {
                        class: 'main-text',
                        text: t('v-3.description-2.main-text-4'),
                    },
                    {
                        class: 'main-text',
                        text: t('v-3.description-2.main-text-5'),
                    },
                    {
                        class: 'main-text',
                        text: t('v-3.description-2.main-text-6'),
                    },
                    {
                        class: 'sub-title',
                        text: t('v-3.description-2.sub-title-1'),
                    },
                    {
                        class: 'list',
                        text: t('v-3.description-2.list-1'),
                    },
                    {
                        class: 'sub-title',
                        text: t('v-3.description-2.sub-title-2'),
                    },
                    {
                        class: 'list',
                        text: t('v-3.description-2.list-2'),
                    },
                    {
                        class: 'sub-title',
                        text: t('v-3.description-2.sub-title-3'),
                    },
                    {
                        class: 'list',
                        text: t('v-3.description-2.list-3'),
                    },
                    {
                        class: 'sub-title',
                        text: t('v-3.description-2.sub-title-4'),
                    },
                    {
                        class: 'main-text',
                        text: t('v-3.description-2.main-text-7'),
                    },
                    {
                        class: 'list',
                        text: t('v-3.description-2.list-4'),
                    },
                    {
                        class: 'sub-title',
                        text: t('v-3.description-2.sub-title-5'),
                    },
                    {
                        class: 'list',
                        text: t('v-3.description-2.list-5'),
                    },
                ],
                price: '1000$',
            }
        ]
        return (
            <div className="vacancies-page">
                {
                    this.state.showForm &&
                    <VacanciesFormT
                        handleClose={this.hideForm.bind(this)}
                        openPopup={this.showPopup.bind(this)}
                        vacancy={this.state.selectedVacancy}
                        eventTracker={this.props.eventTracker}
                    />
                }
                {
                    this.state.showPopup &&
                    <SendPopupT
                        handleClose={this.hidePopup.bind(this)}
                    />
                }
                <div className="vacancies-page__intro">
                    <div className="flex-container">
                        <div className="text-container">
                            <div className="line"></div>
                            <div className="title">{t('intro.title')}</div>
                            <div className="main-text">{t('intro.main-text-1')}<br/> {t('intro.main-text-2')}</div>
                        </div>
                        <div className="image"></div>
                    </div>
                </div>
                <div className="vacancies-page__list">
                    <div className="flex-container">
                        <div className="grid-container">
                            {
                                vacancies.map(item => {
                                    return (
                                        <div
                                            className="grid-item"
                                            onClick={() => this.showForm(item)}
                                        >
                                            <div className="title">{item.title}</div>
                                            <div className="main-text">{item.description1}</div>
                                            <div className="pay">{item.price}</div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>
                <div className="vacancies-page__contacts">
                    <div className="flex-container">
                        <div className="contacts-info">
                            <div className="line"></div>
                            <div className="title">{t('contacts.title')}</div>
                            <div className="contact">
                                <div className="contact__title">
                                    {t('contacts.sub-title-1')}
                                </div>
                                <div className="contact__info">
                                    {t('contacts.main-text-1')}<br/>
                                    {t('contacts.main-text-1-2')}
                                </div>
                            </div>
                            <div className="contact">
                                <div className="contact__title">
                                    {t('contacts.sub-title-2')}
                                </div>
                                <div className="contact__info">
                                    {t('contacts.main-text-2')}
                                </div>
                            </div>
                            <div className="contact">
                                <div className="contact__title">
                                    {t('contacts.sub-title-3')}
                                </div>
                                <div className="contact__info">
                                    {t('contacts.main-text-3')}
                                </div>
                            </div>
                        </div>
                        <div className="downloads">
                            <div className="title">{t('downloads.title')}</div>
                            <div className="main-text">{t('downloads.main-text')}</div>
                            <div className="download-buttons">
                                <div
                                    className="button apple-button"
                                    onClick={() => {
                                        window.open("https://apps.apple.com/en/app/urbanist/id1575719164", "_blank");
                                        this.props.eventTracker('Завантаження', 'Відкрили посилання на AppStore')
                                    }}
                                ></div>
                                <div
                                    className="button android-button"
                                    onClick={() => {
                                        window.open("https://play.google.com/store/apps/details?id=com.dodotap.urbanist", "_blank");
                                        this.props.eventTracker('Завантаження', 'Відкрили посилання на Play Store')
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const VacanciesT = withTranslation('vacancies')(Vacancies);
export default VacanciesT;