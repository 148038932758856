import React from 'react';
import './VacanciesForm.scss';
import InputMask  from 'react-input-mask';
import sendCV from '../../../API/Career';
import { withTranslation } from 'react-i18next';

class VacanciesForm extends React.Component {
  constructor(props) {
    super(props);
    this.fileUpload = React.createRef();
    this.showFileUpload = this.showFileUpload.bind(this);
  }

  state = {
    showForm: false,
    name: '',
    email: '',
    phone: '',
    link: '',
    resume: {file: ''},
  }

  showFileUpload() {
    this.fileUpload.current.click();
  }

  handleFileUpload(e) {
    const fileName = e.target.files[0].name;
    const fileExtension = fileName.split('.').pop();
    if (fileExtension.toLowerCase() !== 'pdf') {
      alert('Прикріпіть файл у форматі PDF.');
      return;
    }
    this.setState(() => ({
      resume: {file: e.target.files[0]},
    }));
  }

  handleSubmit(e) {
    e.preventDefault();
    const {name, email, phone, link, resume} = this.state;
    const formData = new FormData();

    if (phone === '') {
      alert('');
      return;
    }

    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone_number', phone);
    formData.append('portfolio', link);
    formData.append('cv', resume.file);

    const response = sendCV(formData);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error('An error has occurred while uploading information.')
        }
        this.props.eventTracker('Вакансії','Відклик на вакансію');
        this.props.openPopup();
        this.props.handleClose();
      }
    ).catch(alert);
  }

  render() {
    const { t } = this.props;

    return(
      <>
      <div
        className="blur-background"
        onClick={() => this.props.handleClose()}
      ></div>
      <div className="vacancies-form">
        {
          this.state.showForm 
          ? <>
              <form
                className='form'
                onSubmit={(e) => this.handleSubmit(e)}
              >
                <div className="form__inputs">
                  <div className="title">{t('form.title')}</div>
                  <div className="label">{t('form.label-name')}</div>
                  <input
                    type="text"
                    className="text-input required"
                    placeholder={t('form.placeholder-name')}
                    value={this.state.name}
                    onChange={(e) => this.setState({name: e.target.value})}
                    required
                  />
                  <div className="label">{t('form.label-email')}</div>
                  <input
                    type="email"
                    className="text-input required"
                    placeholder={t('form.placeholder-email')}
                    value={this.state.email}
                    onChange={(e) => this.setState({email: e.target.value})}
                    required
                  />
                  <div className="label">{t('form.label-phone')}</div>
                  <InputMask
                    className='text-input required'
                    value={this.state.phone}
                    onChange={e => this.setState({phone: e.target.value})}
                    mask="+ 38 \(099) 999 99 99"
                    maskChar=" "
                    placeholder='+ 38 (0**) *** ** **'
                    required
                  />
                  <div className="label">{t('form.label-portfolio')}</div>
                  <input
                    type="url"
                    className="text-input"
                    placeholder={t('form.placeholder-portfolio')}
                    value={this.state.link}
                    onChange={(e) => this.setState({link: e.target.value})}
                  />
                  <div className="label">{t('form.label-cv')}</div>
                  <div
                    className="text-input file"
                    onClick={this.showFileUpload}
                  >
                    {
                      this.state.resume.file
                      ? <div className="file-name">{this.state.resume.file.name}</div>
                      : <div className="placeholder">{t('form.label-cv')}</div>
                    }
                  </div>
                  <input
                    type="file"
                    className='file-input'
                    ref={this.fileUpload}
                    onChange={(e) => this.handleFileUpload(e)}
                  />
                </div>
                <div className="buttons">
                  <div
                    className="button cancel-button"
                    onClick={() => this.props.handleClose()}
                  >{t('form.cancel-button')}</div>
                  <input className="button send-button" type="submit" value={t('form.send-button-2')} />
                </div>
              </form>
            </>
          : <>
              <div className="info">
                <div className="main-info">
                  <div className="title">{this.props.vacancy.title}</div>
                  <div className="pay">{this.props.vacancy.price} / {t('form.month')}</div>
                </div>
                <div className="text-block">
                  {
                    this.props.vacancy.description2.map(object => {
                     if ( object.class === 'list' ) {
                       const arr = object.text.split('•');
                       arr.shift();
                      return(
                        <div className={object.class}>
                          {arr.map(item => {
                            return (
                              <div className="list-item">• {item}</div>
                            )
                          })}
                        </div>
                      )
                     } else {
                      return(
                        <div className={object.class}>
                          {object.text}
                        </div>
                      )
                     }
                    })
                  }
                </div>
              </div>
              <div className="buttons">
                <div
                  className="button cancel-button"
                  onClick={() => this.props.handleClose()}
                >{t('form.cancel-button')}</div>
                <div
                  className="button send-button"
                  onClick={() => this.setState({showForm: true})}
                >{t('form.send-button')}</div>
              </div>
            </>
        }
      </div>
      </>
    )
  }
}

const VacanciesFormT = withTranslation('vacancies')(VacanciesForm);
export default VacanciesFormT;