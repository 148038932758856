import React from 'react';
import './Header.scss';
import classNames from 'classnames';
import DownloadsPopupT from './DownloadsPopup/DownloadsPopup';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import getCallback from "../../API/Callback";
import CallFormT from "../Partners/CallForm/Form";


class Header extends React.Component {

  state =  {
    popupIsVisible: false,
    showMenu: false,
    showForm: false,
  }

  showPopup() {
    this.setState({popupIsVisible: true});
  }

  hidePopup() {
    this.setState({popupIsVisible: false});
  }

  showForm() {
    this.setState({showForm: true});
  }

  hideForm() {
    this.setState({showForm: false});
  }

  onSubmit() {
    const {name, phone, email} = this.state;

    switch (true) {
      case !name :
        alert('Введіть ім\'я.');
        return;
      case (phone.replace(/\D/g, '').length < 12):
      case !phone :
        alert('Введіть телефон');
        return;
      case !email :
        alert('Введіть імейл.');
        return;
      default:
        break;
    }

    // const formData = new FormData();
    // formData.append('name', name);
    // formData.append('email', email);
    // formData.append('phone_number', phone);

    const data = {
      'name': name,
      'email': email,
      'phone_number': phone
    }

    const response = getCallback(data);
    response.then(
        data => {
          // if (!data.ok) {
          //     throw new Error('An error has occurred while uploading info.');
          // }
          if (data.status !== 200) {
            throw new Error('An error has occurred while uploading info.');
          }

          this.setState({
            showPopup: true,
            name: '',
            phone: '',
            email: '',
          });

          this.props.eventTracker('Партнерам', 'Заповнив форму Передзвонити мені');
        }
    ).catch(alert);
  }

  render() {
    const { t } = this.props;

    return (
      <>
        {
            this.state.showForm &&
            <CallFormT handleClose={this.hideForm.bind(this)} showPopup={this.showPopup.bind(this)}
                       eventTracker={this.props.eventTracker.bind(this)}/>
        }
        <div
          className={classNames("header__menu", {hidden: !this.state.showMenu})}
        >
          <div className="header__menu--nav">
            <nav className='nav'>
              <div className="nav__header">
                <Link to='/'>
                  <div
                    className="main-button"
                    onClick={() => {this.props.changePage('main-page'); this.setState({showMenu: false})}}
                  ></div>
                </Link>
                <div
                  className="back-button"
                  onClick={() => this.setState({showMenu: false})}
                ></div>
              </div>
              <div className="nav__menu">
                <Link to="/about">
                  <div
                    className={classNames("menu-item", {selected: this.props.selectedPage === 'about-us'})}
                    onClick={() => {this.props.changePage('about-us'); this.setState({showMenu: false})}}
                  >{t('about-us')}</div>
                </Link>
                <Link to="/functionality">
                  <div
                    className={classNames("menu-item", {selected: this.props.selectedPage === 'services'})}
                    onClick={() => {this.props.changePage('services'); this.setState({showMenu: false})}}
                  >{t('functionality')}</div>
                </Link>
                <Link to="/news">
                  <div
                    className={classNames("menu-item", {selected: this.props.selectedPage === 'news'})}
                    onClick={() => {this.props.changePage('news'); this.setState({showMenu: false})}}
                  >{t('news')}</div>
                </Link>
                <Link to="/partners">
                  <div
                    className={classNames("menu-item", {selected: this.props.selectedPage === 'partners'})}
                    onClick={() => {this.props.changePage('partners'); this.setState({showMenu: false})}}
                  >{t('partners')}</div>
                </Link>
                <Link to="/faq">
                  <div
                    className={classNames("menu-item", {selected: this.props.selectedPage === 'faq'})}
                    onClick={() => {this.props.changePage('faq'); this.setState({showMenu: false})}}
                  >{t('faq')}</div>
                </Link>
                <Link to="/vacancies">
                  <div
                    className={classNames("menu-item", {selected: this.props.selectedPage === 'vacancies'})}
                    onClick={() => {this.props.changePage('vacancies'); this.setState({showMenu: false})}}
                  >{t('vacancies')}</div>
                </Link>
                <Link to="/contacts">
                  <div
                    className={classNames("menu-item", {selected: this.props.selectedPage === 'contacts'})}
                    onClick={() => {this.props.changePage('contacts'); this.setState({showMenu: false})}}
                  >{t('contacts')}</div>
                </Link>
                <div
                  className="downloads-button"
                  // onClick={() => {this.setState({showMenu: false}); this.showPopup()}}
                  onClick={() => {this.showForm()}}
                >
                  {t('button')}
                </div>
              </div>
            </nav>
          </div>
        </div>
        <header className='header'>
          {
            this.state.popupIsVisible &&
            <DownloadsPopupT eventTracker={this.props.eventTracker.bind(this)} handleClose={this.hidePopup.bind(this)}/>
          }
            <nav className='nav'>
              <Link to="/">
                <div
                  className="main-page--link"
                  onClick={() => this.props.changePage('main-page')}
                ></div>
              </Link>
                <Link to='/about'>
                  <div
                    className={classNames("link",{selected: this.props.selectedPage === 'about-us'})}
                    onClick={() => this.props.changePage('about-us')}
                  >
                    {t('about-us')}
                    </div>
                </Link>
                <Link to='/functionality'>
                  <div
                    className={classNames("link",{selected: this.props.selectedPage === 'services'})}
                    onClick={() => this.props.changePage('services')}
                  >
                    {t('functionality')}
                  </div>
                </Link>
              <Link  to='/news'>
                <div
                  className={classNames("link",{selected: this.props.selectedPage === 'news'})}
                  onClick={() => this.props.changePage('news')}
                >{t('news')}</div>
              </Link>
              <Link  to='/partners'>
                <div
                  className={classNames("link",{selected: this.props.selectedPage === 'partners'})}
                  onClick={() => this.props.changePage('partners')}
                >{t('partners')}</div>
              </Link>
              <Link  to='/faq'>
                <div
                  className={classNames("link",{selected: this.props.selectedPage === 'faq'})}
                  onClick={() => this.props.changePage('faq')}
                >{t('faq')}</div>
              </Link>
              <Link  to='/vacancies'>
                <div
                  className={classNames("link",{selected: this.props.selectedPage === 'vacancies'})}
                  onClick={() => this.props.changePage('vacancies')}
                >{t('vacancies')}</div>
              </Link>
              <Link  to='/contacts'>
                <div
                  className={classNames("link",{selected: this.props.selectedPage === 'contacts'})}
                  onClick={() => this.props.changePage('contacts')}
                >{t('contacts')}</div>
              </Link>
              <div
                className="menu-button"
                onClick={() => this.setState({showMenu: true})}
              ></div>
            </nav>

            <div
              className="downloads-button"
              // onClick={() => this.showPopup()}
              onClick={() => this.showForm()}
            >{t('button')}</div>
        </header>
      </>
    )
  }
}

const HeaderT = withTranslation('header')(Header);
export default HeaderT;