import React from "react";
import './Downloads.scss';
import {useTranslation} from "react-i18next";

function Downloads(props) {
  const {t} = useTranslation('downloads');

  return (
    <div className="container">
      <div className="background-container">
        <div className="downloads">
          <div className="title">{t('title')}</div>
          <div className="sub-title">{t('main-text-1')} <br/>{t('main-text-2')}</div>
          <div className="download-buttons">
            <div
              className="apple-button button"
              onClick={() => {window.open('https://apps.apple.com/en/app/urbanist/id1575719164','_blank'); props.eventTracker('Завантаження','Відкрили посилання на AppStore')}}
            ></div>
            <div
              className="android-button button"
              onClick={() => {window.open('https://play.google.com/store/apps/details?id=com.dodotap.urbanist','_blank'); props.eventTracker('Завантаження','Відкрили посилання на Play Store')}}
            ></div>
          </div>
        </div>
        <div className="part-1"></div>
        <div className="part-2"></div>
      </div>
    </div>
  )
}

export default Downloads;