import React from 'react';
import './App.scss';
import FooterT from './Components/Footer/Footer';
import HeaderT from './Components/Header/Header';
import MainPageTranslated from './Components/MainPage/MainPage';
import PartnersT from './Components/Partners/Partners';
import AboutUsT from './Components/About-Us/AboutUs';
import ServicesT from './Components/Services/Services';
import FAQT from './Components/FAQ/FAQ';
import ContactsT from './Components/Contacts/Contacts';
import NewsT from './Components/News/News';
import Vacancies from './Components/Vacancies/Vacancies';
import NoMatchT from './Components/NoMatch/NoMatch';
import { Routes , Route, HashRouter, Navigate } from 'react-router-dom';
import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-230248264-1';
ReactGA.initialize(TRACKING_ID);

class App extends React.Component {
  state = {
    selectedPage: 'main-page',
  }

  changePage(page) {
    this.setState({selectedPage: page});
  }

  componentDidMount() {
    ReactGA.pageview('/' + window.location.hash.split('/').pop());
    // ReactGA.pageview(window.location.hash + window.location.search);
  }

  componentDidUpdate() {
    ReactGA.pageview('/' + window.location.hash.split('/').pop());
    // ReactGA.pageview(window.location.hash + window.location.search);
  }

  GAEvent(category = 'Test category', action = 'Test action') {
    ReactGA.event({
      category: category,
      action: action,
    });
  }

  update() {
    this.forceUpdate();
  }

  render() {
    return(
      <HashRouter basename='/'>
        <div className="App">
          <HeaderT selectedPage={this.state.selectedPage} changePage={this.changePage.bind(this)} eventTracker={this.GAEvent.bind(this)}/>
          {/* {this.pageSwitch()} */}
          <Routes>
            <Route path="/" element={<Navigate replace to="/main" />} />
            <Route path='/main' element={<MainPageTranslated eventTracker={this.GAEvent.bind(this)} changePage={this.changePage.bind(this)}/>}/>
            <Route path='/about' element={<AboutUsT eventTracker={this.GAEvent.bind(this)}/>}/>
            <Route path='/functionality' element={<ServicesT eventTracker={this.GAEvent.bind(this)}/>}/>
            <Route path='/news' element={<NewsT eventTracker={this.GAEvent.bind(this)}/>}/>
            <Route path='/partners' element={<PartnersT eventTracker={this.GAEvent.bind(this)}/>}/>
            <Route path='/barbershop' element={<PartnersT eventTracker={this.GAEvent.bind(this)}/>}/>
            <Route path='/beauty' element={<PartnersT eventTracker={this.GAEvent.bind(this)}/>}/>
            <Route path='/cosmetic' element={<PartnersT eventTracker={this.GAEvent.bind(this)}/>}/>
            <Route path='/fitness' element={<PartnersT eventTracker={this.GAEvent.bind(this)}/>}/>
            <Route path='/medcentr' element={<PartnersT eventTracker={this.GAEvent.bind(this)}/>}/>
            <Route path='/coffee' element={<PartnersT eventTracker={this.GAEvent.bind(this)}/>}/>
            <Route path='/faq' element={<FAQT eventTracker={this.GAEvent.bind(this)}/>}/>
            <Route path='/vacancies' element={<Vacancies eventTracker={this.GAEvent.bind(this)}/>}/>
            <Route path='/contacts' element={<ContactsT eventTracker={this.GAEvent.bind(this)}/>}/>
            <Route path='/404' element={<NoMatchT update={this.update.bind(this)}/>}/>
            <Route path="*" element={<Navigate replace to="/404" />} />
          </Routes>
          <FooterT eventTracker={this.GAEvent.bind(this)} selectedPage={this.state.selectedPage} changePage={this.changePage.bind(this)}/>
        </div>
      </HashRouter>
    );
  }
}

export default App;
