import React from 'react';
import './MainPage.scss';
import Downloads from '../Downloads/Downloads';
import Slider from '../Slider/Slider';
import OurClientsT from '../OurClients/OurClients';
import getNews from '../../API/News';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import SelectedLanguage from "../../Translation/SelectedLanguage";
import i18next from 'i18next';

class MainPage extends React.Component {

    state = {
        news: []
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.downloadNews();
        i18next.on('languageChanged', () => {
            this.downloadNews();
        })
    }

    componentWillUnmount() {
        i18next.off('languageChanged');
    }

    downloadNews() {
        const response = getNews(SelectedLanguage.value);
        response.then(
            data => {
                if (!data.ok) {
                    throw new Error(this.props.t('error.title'));
                }

                return data.json();
            }
        ).then(
            dataJSON => {
                this.setState({news: dataJSON.results.splice(0, 5)})
            }
        ).catch(alert);
    }

    render() {
        const {t} = this.props;

        return (
            <div
                className="main-page"
            >
                <div className="main-page__intro">
                    <div className="flex-container">
                        <div className="line"></div>
                        <div className="main-page__text">
                            <div className="title">
                                {t('intro.title')}
                            </div>
                            <div className="main-text">
                                {t('intro.main-text')}
                            </div>
                            <div className="downloads ">
                                <div
                                  className="apple-button button"
                                  onClick={() => {window.open('https://apps.apple.com/en/app/urbanist/id1575719164', "_blank"); this.props.eventTracker('Завантаження','Відкрили посилання на AppStore')}}
                                ></div>
                                <div
                                  onClick={() => {window.open("https://play.google.com/store/apps/details?id=com.dodotap.urbanist", "_blank"); this.props.eventTracker('Завантаження','Відкрили посилання на Play Store')}}
                                  className="android-button button"
                                ></div>
                            </div>
                        </div>
                        <div className="phone">

                        </div>
                    </div>
                </div>
                <div className="main-page__intro-2">
                    <div className="flex-container">
                        <div className="images-block">
                            <div className="image-1"></div>
                            <div className="image-2"></div>
                        </div>
                        <div className="intro-2__text">
                            <div className="line"></div>
                            <div className="title">{t('intro-2.title-1')}<span
                                className='title-span'>{t('intro-2.title-2')}</span> <br/>
                                {t('intro-2.title-3')}</div>
                            <div className="main-text">
                                {t('intro-2.main-text')} <br/>
                                {t('intro-2.main-text-2')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-page__services">
                    <div className="background-container">
                        <div className="flex-container">
                            <div className="services__text">
                                <div className="line"></div>
                                <div className="title">
                                    {t('guarantee.title')}
                                </div>
                                <div className="main-text">
                                    {t('guarantee.main-text')}
                                </div>
                                <Link
                                    to="/functionality"
                                    onClick={() => this.props.changePage('services')}
                                >
                                    <div
                                        className="see-all"
                                    >{t('guarantee.button')}</div>
                                </Link>
                            </div>
                            <div className="services__types">
                                <div className="type">
                                    <div className="icon subsription"></div>
                                    <div className="title">{t('guarantee.card-1.title')}</div>
                                    <div className="main-text">
                                        {t('guarantee.card-1.main-text')}
                                    </div>
                                </div>
                                <div className="type">
                                    <div className="icon cards"></div>
                                    <div className="title">{t('guarantee.card-2.title')}</div>
                                    <div className="main-text">
                                        {t('guarantee.card-2.main-text')}
                                    </div>
                                </div>
                                <div className="type">
                                    <div className="icon products"></div>
                                    <div className="title">{t('guarantee.card-3.title')}</div>
                                    <div className="main-text">
                                        {t('guarantee.card-3.main-text')}
                                    </div>
                                </div>
                                <div className="type">
                                    <div className="icon fuel"></div>
                                    <div className="title">{t('guarantee.card-4.title')}</div>
                                    <div className="main-text">
                                        {t('guarantee.card-4.main-text')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-page__coupons">
                    <div className="flex-container">
                        <div className="line"></div>
                        <div className="title">{t('coupons.title')}</div>
                        <div className="main-text">
                            {t('coupons.main-text')}
                        </div>
                    </div>
                </div>
                <div className="main-page__article">
                    <div className="flex-container">
                        <div className="line"></div>
                        <div className="title">{t('article.title')}</div>
                        <div className="article-block">
                            <div className="article-block__content">
                                <div className="content__image"></div>
                                <div className="content__text">{t('article.main-text')}</div>
                            </div>
                            <div
                                className="article-block__button"
                                onClick={() => window.open('https://focus.ua/ukraine/501375-innovaciji-ta-progresivne-maybutnye-dlya-kozhnogo-ukrajincya-urbanist-vidchinyaye-dveri-zruchnosti-de-vse-zhittya-bude-skoncentrovano-v-odnomu-dodatku', '_blank')}
                            >
                                {t('article.button')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-page__downloads">
                    <div className="flex-container">
                        <Downloads eventTracker={this.props.eventTracker.bind(this)}/>
                    </div>
                </div>
                <div className="main-page__news">
                    <div className="flex-container">
                        <div className="news-image"></div>
                        <div className="news-article">
                            <div className="line"></div>
                            <div className="title">{t('news.title')}</div>
                            <div className="main-text">
                                {t('news.main-text')}
                            </div>
                            <div className='parent'>
                                <Slider>
                                    {this.state.news.map(item => {
                                        return (
                                            <>
                                                <div
                                                    key={item.id}
                                                    className='child'
                                                    // onClick={() => this.props.changePage('news')}
                                                >
                                                    <div className="news-container">
                                                        <div key={item.id}
                                                             className="date">{item.date.split('-').join('.')}</div>
                                                        <div key={item.id} className="title">{item.title}</div>
                                                        <div key={item.id}
                                                             className="description">{item.body.split(' ').splice(0, 20).join(' ') + '...'}</div>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="main-page__advantages"
                >
                    <div className="flex-container">
                        <div className="line"></div>
                        <div className="title">{t('functionality.title')}</div>
                        <div className="grid-container">
                            <div className="advantage-card card-1">
                                <div className="line"></div>
                                <div className="title">{t('functionality.card-1.title')}</div>
                                <div className="main-text">{t('functionality.card-1.main-text')}</div>
                            </div>
                            <div className="advantage-card card-2">
                                <div className="line"></div>
                                <div className="title">{t('functionality.card-2.title')}</div>
                                <div className="main-text">{t('functionality.card-2.main-text')}</div>
                            </div>
                            <div className="advantage-card card-3">
                                <div className="line"></div>
                                <div className="title">{t('functionality.card-3.title')}</div>
                                <div className="main-text">{t('functionality.card-3.main-text')}</div>
                            </div>
                            <div className="advantage-card card-4">
                                <div className="line"></div>
                                <div className="title">{t('functionality.card-4.title')}</div>
                                <div className="main-text">{t('functionality.card-4.main-text')}</div>
                            </div>
                            <div className="advantages-phone"></div>
                        </div>
                    </div>
                </div>
                <OurClientsT/>
            </div>
        )
    }
}

const MainPageTranslated = withTranslation('main')(MainPage);

export default MainPageTranslated;