import React from 'react';
import './Popup.scss';
import { withTranslation } from 'react-i18next';

class SendPopup extends React.Component {
  render() {
    const { t } = this.props;

    return(
      <>
      <div
        className="blur-background"
        onClick={() => this.props.handleClose()}
      ></div>
      <div className="send-popup">
        <div className="purple-top"></div>
        <div className="circle-image"></div>
        <div className="title">{t('popup.title')}</div>
        <div className="main-text">
        {t('popup.main-text-1')}<br/>
        {t('popup.main-text-2')}
        </div>
        <div
          className="button"
          onClick={() => this.props.handleClose()}
        >
          {t('popup.button')}
        </div>
      </div>
      </>
    )
  }
}

const SendPopupT = withTranslation('vacancies')(SendPopup);
export default SendPopupT;