import emailJs from '@emailjs/browser';

// const BASE_URL = 'https://urbanist.discount/callback/';

// async function getCallback(body) {
//   const send = () => {
//     return fetch(BASE_URL, {
//       method: 'POST',
//       mode: 'cors',
//       cache: 'default',
//       headers: {
//         "Connection": 'keep-alive',
//       },
//       body: body,
//     })
//   }
//
//   return await send();
// }
async function getCallback(data) {
    return await emailJs.send("service_tb55z1p", "template_t6tosu4", data, 'nFDDsSBYoMdQOsZTu');
}

export default getCallback;
