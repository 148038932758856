import React from 'react';
import './OurClients.scss';
import { withTranslation } from 'react-i18next';

class OurClients extends React.Component {

  state = {
    selectedCategory: 'food',
  }

  render() {
    const { t } = this.props;

    return(
      <div className="our-clients">
        <div className="content">
          <div className="menu">
            <div className="line"></div>
            <div className="title">{t('title')}</div>
          </div>
          <div className="client-logos">
            <div className="logo iqlab"></div>
            <div className="logo polistars"></div>
            <div className="logo soho"></div>
            <div className="logo teatrcomedy"></div>

            {/*<div className="logo frisor"></div>*/}

            <div className="logo forrest"></div>
            <div className="logo bartolomeo"></div>
            <div className="logo paneevino"></div>
            <div className="logo tsunami"></div>
            <div className="logo tittan"></div>

            {/*<div className="logo gvozd"></div>*/}
            {/*<div className="logo loweswing"></div>*/}
            {/*<div className="logo eightcoffee"></div>*/}
            {/*<div className="logo letchik"></div>*/}

            <div className="logo sushi"></div>

            <div className="logo atmosfera"></div>
            <div className="logo neftek"></div>

            {/*<div className="logo chico"></div>*/}
            {/*<div className="logo maximus"></div>*/}

            <div className="logo nail"></div>
          </div>
        </div>
      </div>
    )
  }
}

const OurClientsT = withTranslation('ourclients')(OurClients);
export default OurClientsT;