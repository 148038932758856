async function getNews (lang = 'uk') {
  const get = () => {
    return fetch(`https://urbanist.discount/${lang}/news/`, {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      headers: {
        "Connection": 'keep-alive',
      },
    })
  }

  return await get();
}

export default getNews;