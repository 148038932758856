import React from 'react';
import './Contacts.scss';
import {withTranslation} from 'react-i18next';


class Contacts extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const {t} = this.props;

        return (
            <div className="contacts-page">
                <div className="contacts-page__downloads">
                    <div className="flex-container">
                        <div className="contacts-info">
                            <div className="line"></div>
                            <div className="title">{t('contacts.title')}</div>
                            <div className="contact">
                                <div className="contact__title">
                                    {t('contacts.sub-title-1')}
                                </div>
                                <div className="contact__info">
                                    {t('contacts.main-text-1')}<br/>
                                    {t('contacts.main-text-1-2')}
                                </div>
                            </div>
                            <div className="contact">
                                <div className="contact__title">
                                    {t('contacts.sub-title-2')}
                                </div>
                                <div className="contact__info">
                                    {t('contacts.main-text-2')}
                                </div>
                            </div>
                            <div className="contact">
                                <div className="contact__title">
                                    {t('contacts.sub-title-3')}
                                </div>
                                <div className="contact__info">
                                    {t('contacts.main-text-3')}
                                </div>
                            </div>
                        </div>
                        <div className="downloads">
                            <div className="title">{t('downloads.title')}</div>
                            <div className="main-text">{t('downloads.main-text')}</div>
                            <div className="download-buttons">
                                <div
                                    className="button apple-button"
                                    onClick={() => {
                                        window.open("https://apps.apple.com/en/app/urbanist/id1575719164", "_blank");
                                        this.props.eventTracker('Завантаження', 'Відкрили посилання на AppStore')
                                    }}
                                ></div>
                                <div
                                    className="button android-button"
                                    onClick={() => {
                                        window.open("https://play.google.com/store/apps/details?id=com.dodotap.urbanist", "_blank");
                                        this.props.eventTracker('Завантаження', 'Відкрили посилання на Play Store')
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contacts-page__map">
                    <div className="flex-container">
                        <div className="location-info">
                            <div className="line"></div>
                            <div className="title">{t('map.title')}</div>
                            <div className="sub-title">{t('map.sub-title-1')}</div>
                            <div className="info-text">{t('map.main-text-1')}</div>
                            <div className="info-text">{t('map.main-text-2')}</div>
                            <div className="sub-title">{t('map.sub-title-2')}</div>
                            <div className="info-text">{t('map.main-text-3')}<br/>{t('map.main-text-4')}</div>
                        </div>
                        <div className="location-map">
                            <iframe
                                title="google-map"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2749.6229000102644!2d30.759243299999998!3d46.436353499999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c6338ccdb5dd33%3A0x76b0a1682428e093!2sHenuez&#39;ka%20St%2C%203%D0%B1%2C%20Odesa%2C%20Odes&#39;ka%20oblast%2C%2065000!5e0!3m2!1sen!2sua!4v1652793941550!5m2!1sen!2sua"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                                className='map'
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const ContactsT = withTranslation('contacts')(Contacts);
export default ContactsT;