import React from "react";
import './Footer.scss';
import classNames from "classnames";
import SelectedLanguage from "../../Translation/SelectedLanguage";
import i18next from "i18next";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import HideFooter from "../../Translation/ShowFooter";

class Footer extends React.Component {
  render() {
    const { t } = this.props;

    return(
      <footer className={classNames("footer",{hidden: HideFooter.value})}>
        <div className="container">
          <div className="main">
            <div className="logo-wrapper">
              <Link to="/">
                <div className="logo-text">
                  URBANIST
                  <span>{t('logo')}</span>
                </div>
                <div
                  className="logo"
                  onClick={() => this.props.changePage('main-page')}
                ></div>
              </Link>
              <div
                className="button-up"
                onClick={() => window.scrollTo(0, 0)}
              ></div>
            </div>
            <div className="menu">
              <div className="title">
                {t('menu.title')}
              </div>
              <div className="menu__container">
                <Link to="/about">
                  <div
                    className="menu__link"
                    onClick={() => this.props.changePage('about-us')}
                  >{t('menu.about-us')}</div>
                </Link>
                <Link to="/vacancies">
                  <div
                    className="menu__link"
                    onClick={() => this.props.changePage('vacancies')}
                  >{t('menu.vacancies')}</div>
                </Link>
                <Link to="/functionality">
                  <div
                    className="menu__link"
                    onClick={() => this.props.changePage('services')}
                  >{t('menu.functionality')}</div>
                </Link>
                {/* <div className="menu__link">Галерея</div> */}
                <Link to="/news">
                  <div
                    className="menu__link"
                    onClick={() => this.props.changePage('news')}
                  >{t('menu.news')}</div>
                </Link>
                <Link to="/partners">
                  <div
                    className="menu__link"
                    onClick={() => this.props.changePage('partners')}
                  >{t('menu.partners')}</div>
                </Link>
                <Link to="/faq">
                  <div
                    className="menu__link"
                    onClick={() => this.props.changePage('faq')}
                  >{t('menu.faq')}</div>
                </Link>
              </div>
            </div>
            <div className="contacts">
              <div className="title">{t('contacts')}</div>
              <div className="contacts__email contact">urbanistukraine@gmail.com</div>
              <div className="contacts__phone contact">+380 800 33 5654</div>
              <div className="contacts__phone contact">+380 50 062 9715</div>
              <div className="contacts__phone contact">+380 95 163 6339</div>
            </div>
            <div className="sub-menu">
              <div className="sub-menu__links">
                <div className="downloads-link">
                  <div
                    className="app-download appstore"
                    onClick={() => {window.open("https://apps.apple.com/en/app/urbanist/id1575719164", "_blank"); this.props.eventTracker('Завантаження','Відкрили посилання на AppStore')}}
                  ></div>
                  <div
                    className="app-download google-play"
                    onClick={() => {window.open("https://play.google.com/store/apps/details?id=com.dodotap.urbanist", "_blank"); this.props.eventTracker('Завантаження','Відкрили посилання на Play Store')}}
                  ></div>
                </div>
                <div className="social-links">
                  <div
                    className="social-link linkedin"
                    onClick={() => {window.open('https://www.linkedin.com/in/urbanist-ukraine-71a744230/Linkedin/','_blacnk'); this.props.eventTracker('CоцМережі','Відкритий Linkedin')}}
                  ></div>
                  <div
                    className="social-link youtube"
                    onClick={() => {window.open('https://www.youtube.com/channel/UCvR3zVtBlH_YaOojPadPENw/Youtube','_blacnk'); this.props.eventTracker('CоцМережі','Відкритий Youtube')}}
                  ></div>
                  <div
                    className="social-link instagram"
                    onClick={() => {window.open('https://www.instagram.com/urbanist.city?igsh=bjM2cXo3dHE2a2J1/Instagram','_blacnk'); this.props.eventTracker('CоцМережі','Відкритий Instagram')}}
                  ></div>
                  <div
                    className="social-link facebook"
                    onClick={() => {window.open('https://www.facebook.com/urbanistcity/Facebook','_blacnk'); this.props.eventTracker('CоцМережі','Відкритий Facebook')}}
                  ></div>
                  <div
                    className="social-link twitter"
                    onClick={() => {window.open('https://twitter.com/UrbanistU/Twitter','_blacnk'); this.props.eventTracker('CоцМережі','ЗВідкритий Twitter')}}
                  ></div>
                  <div
                    className="social-link tiktok"
                    onClick={() => {window.open('https://www.tiktok.com/@urbanistukraine?lang=en/Tiktok','_blacnk'); this.props.eventTracker('CоцМережі','Відкритий TikTok')}}
                  ></div>
                </div>
              </div>
              <div className="sub-menu__translation">
                <div 
                  className={classNames("position",{selected: SelectedLanguage.value === 'en'})}
                  onClick={() =>{ SelectedLanguage.value = 'en'; this.forceUpdate();i18next.changeLanguage('en'); this.props.eventTracker('Мова','Зміна мови на англійську')}}
                >EN</div>
                <div
                  className={classNames("position",{selected: SelectedLanguage.value === 'uk'})}
                  onClick={() => { SelectedLanguage.value = 'uk'; this.forceUpdate();i18next.changeLanguage('ua')}}
                >UKR</div>
                <div
                  className={classNames("position",{selected: SelectedLanguage.value === 'ru'})}
                  onClick={() => { SelectedLanguage.value = 'ru'; this.forceUpdate();i18next.changeLanguage('ru'); this.props.eventTracker('Мова','Зміна мови на російську')}}
                >RU</div>
              </div>
            </div>
          </div>
          <div className="lower">
            {t('lower')}
          </div>
        </div>
      </footer>
    )
  }
}

const FooterT = withTranslation('footer')(Footer);
export default FooterT;