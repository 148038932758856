import React from 'react';
import './News.scss';
import Downloads from '../Downloads/Downloads';
import OurClientsT from '../OurClients/OurClients';
import getNews from '../../API/News';
import { withTranslation } from 'react-i18next';
import SelectedLanguage from "../../Translation/SelectedLanguage";
import i18next from 'i18next';


class News extends React.Component {
  state = {
    selectedCategory: 'all',
    news: [],
    articleIsOpened: false,
    article: {},
  }

  componentDidMount() {
    window.scrollTo(0,0);
    this.downloadNews();
    i18next.on('languageChanged',() => {
      this.downloadNews();
    })
  }

  componentWillUnmount() {
    i18next.off('languageChanged');
  }

  downloadNews() {
    const response = getNews(SelectedLanguage.value);
    response.then(
      data => {
        if (!data.ok) {
          throw new Error(this.props.t('error.title'));
        }

        return data.json();
      }
    ).then(
      dataJSON => {
        this.setState({news: dataJSON.results})
      }
    ).catch(alert);
  }


  render() {
    const { t } = this.props;

    return(
      <div className="news-page">
        {
          this.state.articleIsOpened 
          ? <>
              <div
                className="news-page__intro"
                style={{backgroundImage: `url(${this.state.article.photo})`}}
              >
                <div className="flex-container">
                  <div
                    className="return-button"
                    onClick={() => this.setState({articleIsOpened: false})}
                  >{t('intro.button')}</div>
                  <div className="news-container">
                    <div className="date">{this.state.article.date.split('-').join('.')}</div>
                    <div className="title">{this.state.article.title}</div>
                    <div className="main-text">{this.state.article.body.split(' ').splice(0, 26).join(' ')}</div>
                  </div>
                </div>
              </div>
              <div className="news-page__article">
                <div className="flex-container">
                  <div className="title">{this.state.article.title}</div>
                  <div className="main-text">{
                    this.state.article.body.split(/\r?\n/).map(item => {
                      return(
                        <div className="paragraph">{item}</div>
                      )
                    })
                  }</div>
                </div>
              </div>
              <OurClientsT />
            </>
          : <>
              <div className="news-page__list">
                <div className="flex-container">
                  <div className="news-page__list--intro">
                    <div className="line"></div>
                    <div className="title">{t('intro.title')}</div>
                    <div className="main-text">{t('intro.main-text')}</div>
                  </div>
                  <div className="news-page__list--news">
                    {
                      this.state.news.map((item) => {
                        return (
                          <div
                            className="news-container"
                            onClick={() => {window.scrollTo(0, 0); this.setState({
                              articleIsOpened: true,
                              article: item,
                            });}}
                          >
                            <div className="date">{item.date.split('-').join('.')}</div>
                            <div className="title">{item.title}</div>
                            <div className="main-text">{item.body.split(' ').splice(0, 26).join(' ')}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="news-page__downloads">
                <div className="flex-container">
                  <Downloads eventTracker={this.props.eventTracker.bind(this)}/>
                </div>
              </div>
            </>
        }
      </div>
    )
  }
}
const NewsT = withTranslation('news')(News);
export default NewsT;